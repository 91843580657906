<template>
    <div :class="sideNavClass"
          ref="root">
        <!--
 @mouseover="sideNavOpen = true"
         @mouseleave="sideNavOpen = false"
      -->
        <div class="side-nav-strip uk-flex uk-flex-column" v-if="showStrip">
            <div class="strip-part" v-for="color of stripColors" :key="color" :style="{backgroundColor: color}"></div>
        </div>
<!--
        <div class="side-nav-toggle" @click="sideNavOpen = ! sideNavOpen">
            <span class="toggle-icon" uk-icon="icon: triangle-left; ratio: 1"></span>
            <span class="toggle-text">תפריט</span>
        </div>
-->
        <div class="side-nav-inner uk-nav-default  uk-nav-parent-icon  " uk-nav @requestCloseTop="sideNavOpen = false">
            <!--  @requestCloseTop="sideNavOpen = false"-->
            <side-nav-items
                    @openedChildren="sideNavOpen = true"

                    class=""
                    ref="itemsComponent"
                    :items="finalItems[0].children"
                    :parent="finalItems[0]"
                    :is-root="true"
                    :level="0"
            ></side-nav-items>


        </div>
    </div>

</template>

<script>
    import SideNavItems from '@/client/applications/sgirot/components/nav/sideNav/SideNavItems.vue'
    import {computed} from 'vue';
    export default {
        props: {
            showStrip : {
                type: Boolean,
                default: false
            },
            scrollStick: {
                type: Number,
                default: 0
            },
            stripColors: {
                type : Array,
                default: [
                    '#873787',
                    '#5b244f',
                    '#19a6b7',
                    '#9fa542',
                    '#ecc735',
                    '#d59826',
                ]
            },
            items : {
                type: [Boolean, Object],
                default: false,
            },
            isRoot: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            SideNavItems
        },
        data: function () {
            return {
                scrollThrottle: null,
                isWindowScrolled: false,
                sideNavOpen: false,
                currentSubNav: null,
                showingChild: false,
                nav: {
                    0 : {
                       title: ''
                    }
                },

            };
        },
        computed: {
            sideNavClass () {
                let navClass = ['side-nav', 'uk-width-1-2@s', 'uk-flex-stretch'];

                if (this.sideNavOpen) {
                    navClass.push('uk-active');
                } else {
                    navClass.push('uk-closed');
                }

                if (this.isWindowScrolled) {
                    navClass.push('window-scrolled');
                }
                return navClass;
            },
            finalItems() {
                if (this.items) {
                    return this.items;
                }

                return this.localItems;
            },
            localItems() {
                let menuChildren = {
                    0: {
                        text: this.translate('sgirot.nav.projects'),
                        icon: 'bag',
                        notifications: 0,
                        action: 'custom',
                        active: () => {
                            if (this.$router.currentRoute.value.name == 'index') {
                                return true;
                            } else {
                                return false;
                            }

                            let test = this.$router.currentRoute.value.name;
                            return true;
                        },
                        actionData: async () => {
                           let promise =  await this.$router.push({name: 'index'});

                            if (this.$router.currentRoute.value.name == 'index') {
                                window.location.href = '/';
                            } else {
                                this.$router.push({name: 'index'});
                            }

                        },
                        children: false,
                    },
                    1: {
                        text:  this.translate('sgirot.nav.quickAdd'),
                        icon: 'plus',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'project-quick-add'},
                        children: false,
                    },
                    2: {
                        text:  this.translate('sgirot.nav.experts'),
                        icon: 'users',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'expert'},
                        children: false,
                    },
                    3: {
                        text:  this.translate('sgirot.nav.addExpert'),
                        icon: 'user-plus',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'expert-create'},
                        children: false,
                    },
                    4: {
                        text:  this.translate('sgirot.nav.sources'),
                        icon: 'folder',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'source'},
                        children: false,
                    },
                    5: {
                        text:  this.translate('sgirot.nav.addSource'),
                        icon: 'folder-plus',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'source-create'},
                        children: false,
                    },
                    6: {
                        text:  this.translate('sgirot.nav.summary'),
                        icon: 'check-list',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'summary'},
                        children: false,
                    },
                    7: {
                        text:  this.translate('sgirot.nav.logout'),
                        icon: 'sign-out',
                        style: 'primary',
                        class: 'link-error link-sectioned',
                        notifications: 0,
                        action: 'custom',
                        actionData:  () => {
                            this.$store.commit('user/logout');
                            this.$router.push(config.router.loginRoute)
                        }
                    },
                }

                let profile = this.$store.getters['user/profile'];
                if( ! profile.isSuperAdmin) {
                    delete menuChildren[1];
                    delete menuChildren[2];
                    delete menuChildren[3];
                    delete menuChildren[4];
                    delete menuChildren[5];
                }

                let finalChildren = {};

                finalChildren[0] = menuChildren[0];

                if( profile.isSuperAdmin) {
                    finalChildren[1] = menuChildren[1];
                    finalChildren[2] = menuChildren[2];
                    finalChildren[3] = menuChildren[3];
                    finalChildren[4] = menuChildren[4];
                }

                if (menuChildren[5]) {
                    finalChildren[5] = menuChildren[5];
                }

                if (menuChildren[6]) {
                    finalChildren[6] = menuChildren[6];
                }

                if (menuChildren[7]) {
                    finalChildren[7] = menuChildren[7];
                }

                return {
                    0:{
                        text: 'Menu',
                        icon: 'menu',
                        notifications: 0,
                        action: 'showChildren',
                        actionData: null,
                        children: finalChildren,
                    },
                    children: true,
                };
            }
        },
        methods: {
            showSubNav(key) {
                if (this.currentSubNav == key) {
                    this.currentSubNav = null;
                } else {
                    this.currentSubNav = key;
                }
            },
            updateWindowScrollState() {
                if ( utilities.isSSR()) {
                    return;
                }

                this.isWindowScrolled = window.scrollY > this.scrollStick;
            },
            windowScrollHandler(e) {
                clearTimeout(this.scrollThrottle);
                this.scrollThrottle = setTimeout(this.updateWindowScrollState, 1);

            },
            bindWindowScrollHandler(){
                if ( utilities.isSSR()) {
                    return;
                }
                window.addEventListener('scroll', this.windowScrollHandler);
            },
            unbindWindowScrollHandler(){
                if ( utilities.isSSR()) {
                    return;
                }
                window.removeEventListener('scroll', this.windowScrollHandler);
            },
        },
        mounted(){
            this.bindWindowScrollHandler();
        },
        beforeUnmount (){
           this.unbindWindowScrollHandler();
        },
        watch: {
            currentSubNav() {

            },
            sideNavOpen(newVal) {
                if ( ! newVal) {
                    this.currentSubNav = null;
                    this.$refs.itemsComponent.hideItemChildren();
                } else {

                }
            }
        },

    }
</script>

<style scoped lang="scss">

    // TODO : refactor and rearange CSS. this was patched 4 times and hard to handle
    .side-nav {
        --inner-menu-y-padding-mobile : calc(var(--global-margin) * 0.1);
        --inner-menu-y-padding : calc(var(--global-margin) * 4);
        --inner-menu-x-padding : calc(var(--global-margin) * 0.7);
        --inner-menu-x-padding-folded : calc(var(--global-margin) * 0.7);

        // folded total size is 2X x padding + icon size which is equal to global-margin
        --folded-width: calc((var(--inner-menu-x-padding-folded) * 2) + var(--global-margin));

        ---item-transition : 200ms all ease 0ms;
        --menu-transition : 350ms all ease;

        position: fixed;
        top: 0;
        min-height: 100vh;
        padding-top: 80px;
        bottom: 0;
        inset-inline-start: 0;
        z-index: 20;
        background: var(--global-secondary-background);
        width: var(--folded-width);
        transition: all 350ms ease, top 75ms ease-out;

        &.uk-closed .side-nav-inner{
            overflow-x: hidden;
        }

        &::before { // the height of the "top strip" might change. therefore, we need this to make sure theres no gap
            content: ' ';
            bottom: 100%;
            position: absolute;
            left: 0;
            width: 100%;
            height: 80px;
            background: var(--global-secondary-background);
        }

        @media (max-width: 640px) {
           // transform: translateX(calc(var(--folded-width) * -1));
        }

        * {
            color: var(--global-background);
        }

        &.uk-active {
            width: auto;
            transform: none;
            --item-transition : 200ms all ease 150ms;
            --menu-transition : 350ms all ease;
        }

        .side-nav-strip {
            position: fixed;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            z-index: 2;

            .strip-part {
                flex: 100%;
                width: 100%;
            }
        }

        .side-nav-toggle {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            inset-block-start: 100%;
            top: calc(var(--global-margin) * 4.00);
            z-index: 20;
            height: auto;
            padding-inline-end: 10px;
            padding-inline-start: 2px;
            padding-block-start: 3px;
            padding-block-end: 3px;
            width: auto;
            background: var(--global-secondary-background);//$global-color;
            transition: inset-inline-start 150ms ease-in 300ms;
            font-weight: bold;
            cursor: pointer;
            writing-mode: vertical-rl;
            text-orientation: mixed;

            span {
                color: var(--global-background);
            }

            .toggle-icon {
                transition: transform 250ms;
            }


            &:hover {
                span {
                    color: var(--global-primary-background);
                }
            }
        }

        &.uk-active .side-nav-toggle {
            .toggle-icon {
                transform: rotate(180deg);
            }
        }

        .side-nav-inner {
            padding-top: 0;
            height: 100%;
        }
    }




</style>

<style lang="scss">
    // NON SCOPED STYLE
    .side-nav.uk-closed .nav-container{
        overflow-x: hidden;
    }
</style>
