<template>

    <div class="side-nav-items uk-overflow-auto "
        :class="{
            'root' : isRoot,
            'child-active' : children
        }">

        <div class="nav-container uk-flex uk-flex-stretch ">

            <!-- TODO: put parent indicator/nav here -->
            <ul class="uk-list uk-flex uk-flex-column uk-flex-stretch uk-animation-fade uk-animation-fast " v-if=" ! children ">

                <li class="pseudo-sub-nav-item sub-nav-item-title" v-if=" ! children && ! isRoot">
                    <a class="sub-nav-link" href="#" @click.prevent="">
                        <span class="item-icon" :uk-icon="`icon: ${parent.icon}; ratio: 1.0;`"></span>
                        <span class="item-text">{{parent.text}}</span>
                    </a>
                </li>

                <li class="pseudo-sub-nav-item sub-nav-item-back" v-if=" ! isRoot">
                    <a class="sub-nav-link" href="#" @click.prevent="$emit('requestCloseChildren')">
                        <span class="item-icon" :uk-icon="`icon: arrow-left; ratio: 1.0;`"></span>
                        <span class="item-text">Back</span>
                    </a>

                </li>

                <li class="sub-nav-item"
                    v-for="item of items" :key="item"
                    :class="item.class ?? ''"
                >
                    <a class="sub-nav-link"
                       :class="{'active' : isItemActive(item)}"
                       href="#"
                       @click.prevent="runLinkBehaviour(item)">
                        <span class="item-icon" :uk-tooltip="'title: '+item.text+'; pos: left'" :uk-icon="`icon: ${item.icon}; ratio: 1.0;`"></span>
                        <span class="uk-badge item-notification" v-if="item.notifications && item.notifications > 0">{{item.notifications}}</span>
                        <span class="item-text">{{item.text}}</span>
                        <span class="children-indicator" uk-icon="chevron-right" v-if="item.children"></span>
                    </a>
                </li>
            </ul>


            <div class="sub-indicator uk-animation-slide-left-small  uk-flex uk-flex-start uk-flex-middle uk-flex-column"
                 v-if=" children && parent"
                 :style="subIndicatorStyle"
                 @click="hideItemChildren()">
                <div class="click-catcher" @click="hideItemChildren()"></div>
                <span class="sub-indicator-icon" :uk-icon="parent.icon" @click="hideItemChildren()"></span>
            </div>


            <ul class="uk-list uk-flex uk-flex-column uk-flex-stretch uk-animation-fade" v-if=" children ">


                <side-nav-items
                        class="uk-animation-fade"
                        @requestCloseChildren="hideItemChildren()"
                        @requestCloseTop="$emit('requestCloseTop')"
                        @openedChildrent="$emit('openedChildren')"
                        :items="children"
                        :parent="parentItem"
                        :level="(level + 1)"
                        :is-root="false"
                ></side-nav-items>
            </ul>

        </div>

    </div>
</template>

<script>
    import SideNavItems from '@/client/applications/sgirot/components/nav/sideNav/SideNavItems.vue'


    export default {
        props: {
            items : {
                type: [Boolean, Object],
                default: false,
            },
            parent: {
                type: [Boolean, Object],
                default: false,
            },
            isRoot: {
                type: Boolean,
                default: true,
            },
            level: {
                type: Number,
                default: 0,
            }
        },
        name: 'side-nav-items',
        data: function () {
            return {
                childActive: false,

                parentItem: false,
                children: false,
            };
        },
        components: {
            SideNavItems
        },
        emits: [
            'requestCloseChildren', 'openedChildren', 'requestCloseTop'
        ],
        computed: {
          subIndicatorStyle () {
             let  result = {
                 'z-index': 500 - this.level
             };

             return result;
          }
        },
        methods: {
            showItemChildren(item) {
                this.parentItem = item;
                this.children = item.children;
            },
            hideItemChildren() {
                this.parentItem = false;
                this.children = false;
            },
            isItemActive(item) {
                if (item.hasOwnProperty('active')) {
                    return item.active();
                }

                if (item.action !== 'routerNavigation') {
                    return false;
                }

                try {
                    return item.actionData.name === this.$router.currentRoute.value.name;
                } catch (e) {
                    return false;
                }


                return true;
            },
            async runLinkBehaviour(item) {
                switch(item.action) {
                    case 'routerNavigation':
                        this.$router.push(item.actionData);
                        this.$emit('requestCloseTop');
                        break;
                    case 'custom':
                        try {
                            let result = await item.actionData();
                            if (typeof result === 'object' && result !== null && result.closeMenu) {
                                this.$emit('requestCloseTop');
                                this.$emit('requestCloseChildren');
                            }
                        } catch (e) {
                            utilities.debug('error running custom navigation callback!', 2 ,{item: item, error:e});
                        }
                        break;
                    case 'externalLink' :
                        if (typeof window === 'object') {
                            this.$emit('requestCloseTop');
                            this.$emit('requestCloseChildren');

                            window.location = item.actionData;
                        }
                        break;

                    case 'showChildren':
                        this.showItemChildren(item);
                        this.$emit('openedChildren');
                        break;
                        // TODO: external link with target _blank
                    default:
                        break;
                }
            }
        },
        created () {

        }

    }
</script>

<style scoped lang="scss">
    .nav-container {
        height: 100%;
    }

    .side-nav-items {
        margin-top: 0!important;
        height: 100%;
        transition: border-color 200ms ease-in-out;

        &.root {
            border-top: 4px solid var(--global-secondary-background);
        }

        &.root.child-active {
            border-top: 4px solid var(--global-primary-background);
            box-shadow: 0 0 12px 1px rgba(0,0,0,0.3);
        }

        .sub-nav-item, .pseudo-sub-nav-item {
            min-width: 220px;
            margin-top: 0;
            margin-bottom: calc(var(--global-margin)* 0.8);

        }

        .sub-nav-item:first-of-type {
            margin-top: 20px;
        }

        .sub-nav-link {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            overflow: visible;
            color: var(--global-inverse-color);


            padding-top: calc(var(--global-margin) * 0.35);
            padding-bottom:  calc(var(--global-margin) * 0.35);
            padding-inline-start: calc(var(--global-margin) * 0.7);
            padding-inline-end: calc(var(--global-margin) * 1.5);
            transition: all 250ms ease;

            .item-icon {
                margin-inline-end: calc(var(--global-margin) * 0.7);
            }

            .item-text {
                text-transform: uppercase;
                font-size: .875rem;
                margin-inline-end: auto;
                margin-inline-start: 0;
                transition: all 220ms ease-out;
            }

            .children-indicator {
                position: absolute;
                inset-inline-end: calc(var(--global-margin) * 0.4);
                top: 50%;
                transform: translateY(-50%);
                transition: inset-inline-end 125ms ease-in;
            }

            &.active, &:hover {
                background-color: var(--global-primary-background);

                .item-text {
                    text-transform: uppercase;
                    font-size: .875rem;
                }

                .item-notification {
                    background-color: var(--global-inverse-color);
                    color: var(--global-primary-background)!important;
                }

                .children-indicator {
                    inset-inline-end: calc(var(--global-margin) * 0.25);
                }
            }
        }

        .sub-nav-item.link-primary .sub-nav-link {
            background-color: var(--global-primary-background);

            &.active, &:hover {
                background-color: var(--global-link-hover-color);
            }

        }

        .pseudo-sub-nav-item.sub-nav-item-title {
            position: relative;
            margin-bottom: calc(var(--global-margin) * 0);

            .sub-nav-link {
                background-color: var(--global-primary-background-dark);
                color: var(--global-inverse-color);
                font-weight: 600;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: not-allowed;

                padding-top: calc(var(--global-margin) * 0.4);
                padding-bottom: calc(var(--global-margin) * 0.4);

                .item-text {
                    margin: 0;
                }

                &:hover {
                    .item-text {

                        margin-inline-start: 0;
                        margin-inline-end: 0;
                    }
                }
            }
        }

        .pseudo-sub-nav-item.sub-nav-item-back {
            margin-bottom: calc(var(--global-margin) * 0.75);

            .sub-nav-link {

                background-color: var(--global-color);
                box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.4);

                .item-text{
                    margin-inline-start: inherit;
                    margin-inline-end: auto;
                }

                .item-icon {
                    position: relative;
                    inset-inline-start: 0;
                    transition: inset-inline-start 150ms ease;
                }
                &:hover {
                    background-color: var(--global-link-hover-color);

                    .item-icon {
                        inset-inline-start: calc(var(--global-margin) * -0.3);
                    }
                }
            }
        }


        .sub-indicator {
            position: relative;
            width: auto;
            padding: calc(var(--global-margin) * 0.35) calc(var(--global-margin) * 0.35);
            //background: var(--global-primary-background);
            //color: var(--global-secondary-background);
            color: var(--global-inverse-color);
            background-color: var(--global-color);
            box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.4);

            .sub-indicator-icon {
                z-index: 1;
            }

            .click-catcher {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                z-index: 2;
                cursor: pointer;
            }
        }
    }

    .item-notification {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        inset-inline-start: calc(var(--global-margin) * 1.15);
        top: calc(var(--global-margin) * -0.17);
        height: 18px;
        width: 18px;
        min-width: 18px;
        flex: 0 0 18px;
        line-height: 18px;
        font-size: 12px;
        transition: background-color 250ms ease, color 250ms ease;

        &:hover {
            color: var(--global-primary-background);
        }
        .is-rtl & {
           // right: calc(var(--global-margin) * 1.15);
           // left: auto;
        }

    }


</style>
